//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: var(--#{$prefix}secondary-color);
    }
  }
}

.nav-pills {
  > a {
    color: var(--#{$prefix}secondary-color);
  }
}

// Nav tab custom

.nav-tabs-custom {
  .nav-item {
    margin: 0px 7px;
    position: relative;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }

    .nav-link {
      background-color: var(--#{$prefix}tertiary-bg);
      &.active {
        color: $primary;
        &:after {
          transform: scale(1);
        }
      }

      &:after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      .nav-icon {
        font-size: 24px;
      }
    }
  }
}
